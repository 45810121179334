export const IS_DEV_MODE = process.env.REACT_APP_NODE_ENV !== 'production';

export const CHAIN_ID = {
  ETH: 1,
  BSC: 56,
  BSC_TESTNET: 97,
  OP: 10,
  AVAX: 43114,
  ARB: 42161,
  ARB_TESTNET: 421614,
  BASE: 8453,
}

export const MAIN_CHAIN_ID = IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC ;
export const APPLICATION_URL = window.location.origin
export const APPLICATION_NAME = 'Ape Launchpad'
export const KYC_CLIENT_ID = process.env.REACT_APP_KYC_CLIENT_ID || 'ape_terminal_9eafe';
export const APPLICATION_WEB3_SIGNIN_MSG = `Sign in to the ${APPLICATION_NAME}`
//export const SERVER_URL = process.env.REACT_APP_SERVER_URL || 'https://api-ng.apeterminal.io/api';
export const SERVER_URL = 'https://ape-launchpad-backend-6867088bb0b2-uat.azurewebsites.net/api' // FIXME: remove this when done testing stg on eachop
export const GOOGLE_CLIENT_ID = process.env?.REACT_APP_GOOGLE_CLIENT_ID || "733766919259-u5t95890bv6q8beidh2i7bk0qef0q5fd.apps.googleusercontent.com";
export const SOCKET_HOST = process.env.REACT_APP_SOCKET_HOST || 'https://socket.ape-lauchpool.eachop.tech/notification'

// gulf countries are not allowed to join the sales
export const IS_GCC_WEBSITE = window.location.hostname === "gcc.apeterminal.io";

// export const RPC_URL = {
//   BSC:  "https://bsc.blockpi.network/v1/rpc/public",
//   ETH: "https://ethereum.blockpi.network/v1/rpc/public",
//   ARB: "https://arbitrum.blockpi.network/v1/rpc/public",
//   AVAX: "https://avalanche.blockpi.network/v1/rpc/public",
// };

export const RPC_URL = {
  BSC:  "https://rpc.ankr.com/bsc",
  [CHAIN_ID.BSC]: "https://rpc.ankr.com/bsc",
  ETH: "https://rpc.ankr.com/eth",
  [CHAIN_ID.ETH]: "https://rpc.ankr.com/eth",
  ARB: "https://rpc.ankr.com/arbitrum",
  [CHAIN_ID.ARB]: "https://rpc.ankr.com/arbitrum",
  AVAX: "https://rpc.ankr.com/avalanche",
  [CHAIN_ID.AVAX]: "https://rpc.ankr.com/avalanche",
  BSC_TESTNET: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [CHAIN_ID.BSC_TESTNET]: 'https://rpc.ankr.com/bsc_testnet_chapel',
  [CHAIN_ID.BASE]: 'https://rpc.ankr.com/base',
};

export const CONTRACT_ADDRESS = {
  APE_INVESTMENT: {
    [CHAIN_ID.BSC]: "0x2aF527Bd9fd71b98494c6404f8Af5011b17a1ed7",
    [CHAIN_ID.ETH]: "0x6B02dA8eE04494B324Cb400D6d8Cfac239d4FDc5",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_VESTING_UNIFIED: {
    // 20Nov'24 - deprecated since refund is moved to BSC
    // [CHAIN_ID.ETH]: "0x5c9Eb2c169b0110C30c7adA7E53D76d1fCcFDeB4",
    // 27Nov'24
    [CHAIN_ID.ETH]: "0x8407490c88667C1C5Ca2910f95Dd4027C84e1804",
    [CHAIN_ID.ARB]: "",
    [CHAIN_ID.BASE]: "0x33b239885466b3a5791bFAb7023752950784D122",
    [CHAIN_ID.BSC]: "0x632137641FFd923217c4b289C4159Ee480360098",
    [CHAIN_ID.AVAX]: "0x3614d378ab6334F65942dA5d4A5423710bCcD362",
    [CHAIN_ID.ARB_TESTNET]: "0x0db1d1b956ca01cfa3108921e5fe22973e0e0873",
    [CHAIN_ID.BSC_TESTNET]: "0x0cc5c47089351d889fe082661fb13bff1ba2a632",
  },
  APE_INVESTMENT_UNIFIED: {
    [CHAIN_ID.BSC]: "0x9B0Eba47330C54E8Ec051F673319de2046e6dA2e",
    [CHAIN_ID.BSC_TESTNET]: "0xd2a32A6a39f874EF3cbA0B3aec9e6F6Fc4b8cD4B",
  },
  APE_REFUND_UNIFIED: {
    [CHAIN_ID.BSC]: "0xE27E1b4B593fC4bCA68bBD753133C88D519E8B8b",
    [CHAIN_ID.BSC_TESTNET]: "0x7c273601600f4dfda3742aae2f341a8c1fa1a124",
  },
  USDC: {
    [CHAIN_ID.BSC]: "0x8AC76a51cc950d9822D68b83fE1Ad97B32Cd580d",
    [CHAIN_ID.BSC_TESTNET]: "0xbA386FB039f7d35BE74214e88aBe9D0d055139Bd",
    [CHAIN_ID.ARB_TESTNET]: "0xb0ef8029ab21baab33d3b8eda9792d30f9057f74",
  }
}

export const WALLET_CONNECT_PROJECT_ID = "1a7e8d507a1b268947b7a23c971a35b3";

// we try to make less database changes as possible on the migration to Azure from AWS
// so will only do a manual endpoint swap on frontend
export const ASSETS_CDN_ENDPOINT_DEPRECATED = "https://launchpad-bucket.s3.amazonaws.com";
export const ASSETS_CDN_ENDPOINT = "https://cdn.apeterminal.io";

export const ENABLE_ANALYTIC = window.location.hostname.endsWith("apeterminal.io");

export const ETH_TOPIC_HASH = {
  // Transfer (index_topic_1 address from, index_topic_2 address to, uint256 value)
  ERC20_TRANSFER: "0xddf252ad1be2c89b69c2b068fc378daa952ba7f163c4a11628f55a4df523b3ef",
}

export const DEFAULTS :Record<string, any> = {
  PROJECT_AVATAR_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-avatar.svg",
  // PROJECT_BANNER_URL: "https://placehold.co/760x404/000000/636363.jpg"
  PROJECT_BANNER_URL: "https://ape-launchpad-cdn.b-cdn.net/shared/comingsoon-cover.svg"
}

export const FIREBASE_CONFIG = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY || "AIzaSyBbStaoWZQ9WQA9RtZ1pvDz7z1u_1lGI4I",
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN || "ape-fcm-test.firebaseapp.com",
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID || "ape-fcm-test",
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET || "ape-fcm-test.firebasestorage.app",
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGING_SENDER_ID || "877958911089",
  appId: process.env.REACT_APP_FIREBASE_APP_ID || "1:877958911089:web:cbe5186f852794d26a77d0"
}
