import _ from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import OrangeButton from '../../components/OrangeButton'
import { Box, Typography } from '@mui/material'
import { multicall, switchChain } from '@wagmi/core'
import dayjs from 'dayjs'
import { BigNumber, Contract, ethers } from 'ethers'
import moment from 'moment'
import { AbiFunction } from 'viem'
import { useAccount, useReadContract, useWriteContract } from 'wagmi'
import {
  ClaimMetrics1,
  ClaimMetrics2,
  ClaimMetrics3,
  ClaimMetrics4,
  GreenFilledArrow,
  GreenTick,
} from '../../assets'
import ClaimSuccessModal from '../../components/ClaimSuccessModal'
import GreenButton from '../../components/GreenButton'
import { useEthersSigner, wagmiConfig } from '../../components/Web3Provider'
import {
  CHAIN_ID,
  CONTRACT_ADDRESS,
  MAIN_CHAIN_ID,
  RPC_URL,
  ETH_TOPIC_HASH,
  IS_DEV_MODE,
} from '../../constant'
import InvestmentUnifiedAbi from '../../contracts/ape-investment-unified.json'
import VestingUnifiedAbi from '../../contracts/ape-vesting-unified.json'
import RefundUnifiedAbi from '../../contracts/ape-refund-unified.json'
import ApeRefundProofGeneratorAbi from '../../contracts/ape-refund-proof-generator.json'
import refundUserByProject from '../../contracts/refund-user.json'
import ClaimNewAbi from '../../contracts/claim_new.json'
import InvestmentAbi from '../../contracts/ape-investment.json'
import Erc20Abi from '../../contracts/erc-20.json'
import {
  consoleLog,
  formatEthBalanceLite,
  formatBigNumber,
  getChainIdFromName,
  getEpoch,
} from '../../utils/index'
import { StyledBox } from './IDODetails'
import InfoBox from './InfoBox'
import { getPr0x1edVestingAddress } from './pr0x1edVesting'
import Countdown from 'react-countdown'
import toaster from 'react-hot-toast'
import { getExceptionMessage, getChainName } from '../../utils/index'

interface IUnifiedVestingClaimInfoStats {
  refundRequestedAt: number
  refundedAt: number
  claimedAmountBI: bigint
  claimableAmountBI: bigint
  tokenDeposited: bigint
  tokenRemains: bigint
  allocationAmountBI: string
  tokenSymbol: string
  tokenDecimals: number
  vestingDuration: number
}

interface IUnifiedVestingStats {
  refundRequestedAt: number
  refundedAt: number
  claimedAmountBI: bigint
  claimableAmountBI: bigint
  allocationAmountBI: bigint
  refundDeadlineAt?: number
  projectData?: any
  tokenSymbol: string
  refundTokenSymbol?: string
  isRefundTokenReady?: boolean
}

interface ILegacyVestingStats {
  getClaimableAmountData: bigint
  tgeData: bigint
  durationData: bigint
  cliffData: bigint
  tokenDecimalData: bigint
  claimedAmountData: bigint
  tgeAmount: bigint
  vestingAmount: bigint
}

interface ICommonVestingStats {
  tokenSymbol: string
  totalTokens: bigint
  claimableTokens: bigint
  claimedTokens: bigint
  tokenDecimals: number
  vestingEndAt: number
  //                  pre-tge  -> cliff  -> vesting -> ended -> claimed
  vestingStatus:
    | ''
    | 'locked'
    | 'cliff'
    | 'unlocking'
    | 'unlocked'
    | 'claimed'
    | 'refunded'
    | string
  refundRequestedAt: number
  refundDeadlineAt: number
  refundTokenAmount: bigint
  refundTokenDecimals: bigint
  refundTokenAddr: string
  refundTokenSymbol: string
  remainingTokens: bigint
}

const VESTING_UNIFIED_ABI: AbiFunction[] = VestingUnifiedAbi as AbiFunction[]
const REFUND_UNIFIED_ABI: AbiFunction[] = RefundUnifiedAbi as AbiFunction[]
const VESTING_UNIFIED_ABI_NEW: AbiFunction[] = ClaimNewAbi as AbiFunction[]
const ERC20_ABI: AbiFunction[] = Erc20Abi as AbiFunction[]
const APE_INVESTMENT_UNIFIED_ABI: AbiFunction[] =
  InvestmentUnifiedAbi as AbiFunction[]

interface ICountdownTextProps {
  targetEpoch: number
  onComplete?: () => void
  hideOnCompleted?: boolean
  prefixText?: string
  postfixText?: string
  styles?: Record<string, any>
}
const CountdownText = (props: ICountdownTextProps) => {
  const {
    targetEpoch,
    onComplete,
    hideOnCompleted,
    prefixText,
    postfixText,
    styles,
  } = props
  return (
    <Countdown
      date={targetEpoch * 1000}
      onComplete={onComplete}
      renderer={({ days, hours, minutes, seconds, completed }) => {
        let _timerString = ''
        if (days > 0) {
          _timerString = `${days}d ${hours}h ${minutes}m ${seconds}s`
        } else if (hours > 0) {
          _timerString = `${hours}h ${minutes}m ${seconds}s`
        } else if (minutes > 0) {
          _timerString = `${minutes}m ${seconds}s`
        } else {
          _timerString = `${seconds}s`
        }
        if (completed && hideOnCompleted) return null
        return (
          <Typography
            fontFamily={'Inter'}
            fontWeight={600}
            // fontSize={14}
            // lineHeight={'16.8px'}
            sx={{}}
            display={'inline'}
            {...styles}
          >
            <span>
              {prefixText}
              {_timerString}
              {postfixText}
            </span>
          </Typography>
        )
      }}
    />
  )
}

const getPastClaimedAmount = async (
  accountAddress: string,
  chainId: number,
  pastClaimContracts: string[] | any,
) => {
  if (!_.isArray(pastClaimContracts) || !accountAddress || !chainId) return 0n
  try {
    const pastClaimedAmounts = await Promise.all(
      pastClaimContracts.map((claimContractAddress: string) => {
        const claimContract = new ethers.Contract(
          claimContractAddress,
          ClaimNewAbi,
          new ethers.providers.JsonRpcProvider(RPC_URL[chainId]),
        )
        return claimContract.claimedAmount(accountAddress)
      }),
    )
    return pastClaimedAmounts.reduce((x, y) => BigInt(x || 0) + BigInt(y || 0), 0n)
  } catch (e) {
    console.error(e)
    return 0n
  }
}

const getPastAirdropedAmount = async (
  accountAddress: string,
  chainId: number,
  pastAirdropTxs: string[] | any,
) => {
  if (!_.isArray(pastAirdropTxs) || !accountAddress || !chainId) return 0n
  let pastAirdropedAmount: bigint = 0n
  const defaultAbiCoder = ethers.utils.defaultAbiCoder
  try {
    await Promise.all(
      pastAirdropTxs.map(async (txHash: string) => {
        const provider = new ethers.providers.JsonRpcProvider(
          RPC_URL[chainId],
        )
        const receipt = await provider.getTransactionReceipt(txHash)
        const logs = _.get(receipt, 'logs')
        if (!_.isArray(logs)) return
        logs.forEach(log => {
          const { topics, data } = log
          const logTopic = _.get(topics, 0)
          if (logTopic !== ETH_TOPIC_HASH.ERC20_TRANSFER) return
          const [toAddress] = defaultAbiCoder.decode(
            ['address'],
            _.get(topics, 2),
          )
          const [amount] = defaultAbiCoder.decode(['uint256'], data)
          if (toAddress !== accountAddress) return
          pastAirdropedAmount += BigInt(amount)
        })
      }),
    )
  } catch (e) {
    console.error(e)
  }
  return pastAirdropedAmount
}

const PONDER_PROJECT_OID = "65f1c302ee95544c6558f145";
const MICROGPT_PROJECT_OID = "6735e623d93397346746b482";
const RWA_INC_OID = "673de40392f33ef5f26c1387";
const BRAVO_READY_OID = "673f2e402eda3206ccc98a3b";
const MIN_GAS_LIMIT :Record<string, BigInt> = {
  UV_CLAIM: 250_000n,
  UV_REFUND_REQUEST: 250_000n,
}
// #warning: currently getting a big render here

const GeneralClaim = ({ idoData, projectId }: any) => {
  const [isLoading, setIsLoading] = useState(false)
  // chainId is the connected network
  const { address, chainId } = useAccount()
  const [openClaimSuccessModal, setOpenClaimSuccessModal] = useState(false)
  const [isShowClaimOnholdModal, setIsShowClaimOnholdModal] = useState(false)
  const [nonce, setNonce] = useState<number>(0)
  const signer = useEthersSigner({ chainId })
  const signerBSC = useEthersSigner({ chainId: IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC })
  const projectOid = _.get(idoData, 'project._id');
  const pastClaimContracts = idoData?.pastClaimContracts;
  const pastAirdropTxs = idoData?.pastAirdropTxs;

  // for unified invest-vesting - required
  // FIXME: should not be fall back to zero
  const refundProjectId = Number(idoData?.refundProjectId) ||Number(idoData?.project?.claimId)
  const investmentProjectId = Number(idoData?.investmentProjectId) || Number(idoData?.project?.contractId) || 0
  const claimChainId: number | undefined = getChainIdFromName(
    idoData?.claimNetwork || idoData?.project?.token?.network,
  )

  // Old: idoData?.claimContract
  const claimContract = idoData?.claimContract || CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]
  // refund always on BSC
  const refundChainId :number = IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC;
  const apeInvestmentUnifiedContract = useMemo(() => {
    let address :string = CONTRACT_ADDRESS.APE_INVESTMENT_UNIFIED[MAIN_CHAIN_ID];
    // those are special case where we still using legacy investment contract
    // legacy investment contract dont have ability to generate investment proof so need a polyfill contract
    if([
      PONDER_PROJECT_OID,
      MICROGPT_PROJECT_OID,
      RWA_INC_OID,
      BRAVO_READY_OID,
    ].includes(projectOid)) {
      address = "0x597b53a550e788cd7fbfb6f281dade989c107293";
    }

    return new ethers.Contract(
      address,
      // @ts-ignore
      APE_INVESTMENT_UNIFIED_ABI,
      new ethers.providers.JsonRpcProvider(RPC_URL[MAIN_CHAIN_ID]),
    );
  }, [projectOid])

  const apeRefundUnifiedContract = useMemo(() => {
    return new ethers.Contract(
      CONTRACT_ADDRESS.APE_REFUND_UNIFIED[IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC],
      // @ts-ignore
      REFUND_UNIFIED_ABI,
      new ethers.providers.JsonRpcProvider(RPC_URL[IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC]),
    );
  }, [])
  // undefined means loading
  // false mean legacy vesting
  // true mean unified vesting
  const [unifiedVestingStats, setUnifiedVestingStats] = useState<
    IUnifiedVestingStats | false | undefined
  >()
  // undefined means loading
  // true mean legacy vesting
  // false mean unified vesting
  const [legacyVestingStats, setLegacyVestingStats] = useState<
    ILegacyVestingStats | false | undefined
  >()

  const [unifiedVestingClaimInfoStats, setUnifiedVestingClaimInfoStats] =
    useState<IUnifiedVestingClaimInfoStats | undefined>(undefined)

  // for unified invest-vesting
  const claimProjectId = Number(idoData?.project?.vestingId)

  const { data: proof } = useReadContract({
    address: CONTRACT_ADDRESS.APE_INVESTMENT[claimChainId!] as `0x${string}`,
    abi: InvestmentAbi,
    functionName: 'getInvestmentProof',
    args: [idoData?.project?.contractId, address],
  })

  const { writeContractAsync: claimToken } = useWriteContract()

  let isUnifiedVesting =
    claimContract &&
    claimChainId &&
    (CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId] === claimContract || CONTRACT_ADDRESS.APE_REFUND_UNIFIED[claimChainId] == claimContract)

  if(PONDER_PROJECT_OID == projectId || MICROGPT_PROJECT_OID == projectId) {
    isUnifiedVesting = true;
  }

  const isRefundOnly = claimChainId && isUnifiedVesting && CONTRACT_ADDRESS.APE_REFUND_UNIFIED[claimChainId] == claimContract;

  // check legacy vesting
  useEffect(() => {
    if (!claimContract || !claimChainId || isUnifiedVesting) return
    if(!address) {
      setLegacyVestingStats(undefined);
      return;
    }

    const _claimContract = getPr0x1edVestingAddress(claimContract, address);

    ;(async () => {
      const legacyVestingContract = {
        address: _claimContract as `0x${string}`,
        abi: ClaimNewAbi as AbiFunction[],
      } as const

      const pastClaimedAmountPromise = getPastClaimedAmount(address, claimChainId, pastClaimContracts);
      const pastAirdropedAmountPromise = getPastAirdropedAmount(address, claimChainId, pastAirdropTxs);

      const multicallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            ...legacyVestingContract,
            functionName: 'getClaimableAmount',
            args: [address],
          },
          {
            ...legacyVestingContract,
            functionName: 'tge',
          },
          {
            ...legacyVestingContract,
            functionName: 'duration',
          },
          {
            ...legacyVestingContract,
            functionName: 'cliff',
          },
          {
            ...legacyVestingContract,
            functionName: 'returnTokenDecimal',
          },
          {
            ...legacyVestingContract,
            functionName: 'claimedAmount',
            args: [address],
          },
          {
            ...legacyVestingContract,
            functionName: 'tgeAmount',
          },
          {
            ...legacyVestingContract,
            functionName: 'linearUnlockAmount',
          },
        ],
        chainId: claimChainId,
      })

      const pastClaimedAmount = await pastClaimedAmountPromise;
      const pastAirdropedAmount = await pastAirdropedAmountPromise;

      setLegacyVestingStats({
        getClaimableAmountData: multicallResp[0].result,
        tgeData: multicallResp[1].result,
        durationData: multicallResp[2].result,
        cliffData: multicallResp[3].result,
        tokenDecimalData: multicallResp[4].result,
        // proxy vesting is a special case since pastClaimedAmount already included in multicallResp[5].result
        // claimedAmountData: multicallResp[5].result,
        claimedAmountData: multicallResp[5].result + (_claimContract != claimContract ? 0n : pastClaimedAmount) + pastAirdropedAmount,
        tgeAmount: multicallResp[6].result,
        vestingAmount: multicallResp[7].result,
      })
    })()
  }, [claimChainId, isUnifiedVesting, claimContract, address, nonce])

  // check unified vesting
  // TODO: just in case refundProjectId diffirent from claimProjectId, we need to add polyfill for that
  useEffect(() => {
    if (!isUnifiedVesting) return
    if (!(claimProjectId >= 0)) {
      console.error('claimProjectId is not set')
      return
    }
    if(!address) {
      setUnifiedVestingStats(undefined);
      return;
    }
    ;(async () => {
      const vestingUnifiedContract = {
        address: claimContract as `0x${string}`,
        abi: VESTING_UNIFIED_ABI,
      } as const

      const refundStatsOnBscPromise = apeRefundUnifiedContract.getAccountStatsAt(refundProjectId, address);
      const refundProjectBscPromise = apeRefundUnifiedContract.getProject(refundProjectId);

      const multicallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            ...vestingUnifiedContract,
            functionName: 'getAccountStatsAt',
            args: [claimProjectId, address],
          },
          {
            ...vestingUnifiedContract,
            functionName: 'getProject',
            args: [claimProjectId],
          },
        ],
        chainId: claimChainId,
      })

      const projectData = multicallResp[1].result

      const tokenMulticallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            address: projectData.tokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
          {
            address: projectData.refundTokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
        ],
        chainId: claimChainId,
      })

      let refundStatsOnBsc :any;
      let refundProjectBsc :any;

      try {
        refundStatsOnBsc = await refundStatsOnBscPromise;
        refundProjectBsc = await refundProjectBscPromise;
      } catch(e) {
        console.error(e);
      }

      const [
        refundRequestedAtBI,
        refundedAt,
        claimedAmountBI,
        claimableAmountBI,
      ] = multicallResp[0].result

      setUnifiedVestingStats({
        refundRequestedAt: isRefundOnly ? Number(projectData.refundDeadlineAt) - 1 : Number(refundRequestedAtBI),
        refundedAt: Number(refundStatsOnBsc?.refundedAt || 0),
        claimedAmountBI: isRefundOnly ? (Number(refundStatsOnBsc?.refundedAt) > 0 ? refundProjectBsc.refundAmount.toBigInt() : 0n) : claimedAmountBI,
        claimableAmountBI: isRefundOnly ? (Number(refundStatsOnBsc?.refundedAt) > 0 ? 0n : refundProjectBsc.refundAmount.toBigInt()) : claimableAmountBI,
        refundDeadlineAt: Number(projectData.refundDeadlineAt),
        isRefundTokenReady: refundProjectBsc.refundTokenDeposited.toBigInt() > 0n,
        projectData: {
          ...projectData,
          tgeTime: moment(Number(projectData.tgeAt) * 1000).format(
            'MMMM Do YYYY, hh:mm:ss a',
          ),
          refundDeadlineTime: moment(
            Number(projectData.refundDeadlineAt) * 1000,
          ).format('MMMM Do YYYY, hh:mm:ss a'),
        },
        allocationAmountBI: projectData.tgeAmount + projectData.vestingAmount,
        tokenSymbol: isRefundOnly ? "USDC" : tokenMulticallResp[0].result,
        refundTokenSymbol: isRefundOnly ? "USDC" : tokenMulticallResp[1].result,
      })
    })()
  }, [claimContract, isUnifiedVesting, claimProjectId, address, nonce, isRefundOnly])

  // info data claim
  useEffect(() => {
    if (!isUnifiedVesting) return
    if (!(claimProjectId >= 0)) {
      console.error('claimProjectId is not set')
      return
    }
    if (!address) {
      setUnifiedVestingClaimInfoStats(undefined)
      return
    }
    ;(async () => {
      const vestingUnifiedContract = {
        address: claimContract as `0x${string}`,
        abi: VESTING_UNIFIED_ABI_NEW,
      } as const
      const multicallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            ...vestingUnifiedContract,
            functionName: 'getAccountStatsAt',
            args: [claimProjectId, address],
          },
          {
            ...vestingUnifiedContract,
            functionName: 's_project',
            args: [claimProjectId],
          },
        ],
      })
      const [
        id,
        name,
        active,
        investors,
        merkleProofRoot,
        tgeAt,
        tgeAmount,
        cliffDuration,
        vestingDuration,
        vestingAmount,
        tokenAddr,
        tokenDeposited,
        tokenRemains,
        tokenDecimals,
        refundInvestors,
        refundDeadlineAt,
        refundTokenAddr,
        refundAmount,
        refundTokenDecimals,
        refundTokenDeposited,
        refundTokenRemains,
      ] = multicallResp?.[1]?.result

      const tokenMulticallResp: any[] = await multicall(wagmiConfig, {
        contracts: [
          {
            address: tokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
          {
            address: refundTokenAddr as `0x${string}`,
            abi: ERC20_ABI,
            functionName: 'symbol',
          },
        ],
      })

      const [
        refundRequestedAtBI,
        refundedAtBI,
        claimedAmountBI,
        claimableAmountBI,
      ] = multicallResp?.[0]?.result

      const unifiedVestingClaimInfoData = {
        refundRequestedAt: Number(refundRequestedAtBI),
        refundedAt: Number(refundedAtBI),
        claimedAmountBI: claimedAmountBI,
        claimableAmountBI:  claimableAmountBI,
        tokenDeposited: tokenDeposited.toString(),
        tokenRemains: tokenRemains.toString(),
        allocationAmountBI: (tgeAmount + vestingAmount).toString(),
        tokenSymbol: tokenMulticallResp[0].result,
        tokenDecimals: tokenDecimals,
        vestingDuration: Math.round(Number(vestingDuration) / 1000),
      }
      setUnifiedVestingClaimInfoStats(unifiedVestingClaimInfoData)
    })()
  }, [claimContract, isUnifiedVesting, claimProjectId, address, nonce])

  const iconStartFn = useCallback(() => (
    <img
      src={GreenFilledArrow}
      style={{ marginRight: '7px' }}
      alt="forward icon"
    />
  ), []);

  const currentTime = dayjs()

  // consoleLog('unifiedVestingStats', unifiedVestingStats)
  // consoleLog('legacyVestingStats', legacyVestingStats)

  // let commonVestingStats :Partial<ICommonVestingStats>|undefined = undefined;
  let commonVestingStats: Partial<ICommonVestingStats> = {}
  if (legacyVestingStats) {
    const {
      getClaimableAmountData,
      tgeData,
      durationData,
      cliffData,
      tokenDecimalData,
      claimedAmountData,
      tgeAmount,
      vestingAmount,
    } = legacyVestingStats
    let vestingStatus = ''
    const tgeAtDjs = dayjs(Number(tgeData) * 1000)
    const cliffEndDateDjs = dayjs(Number(tgeData) * 1000).add(
      Number(cliffData),
      'seconds',
    )
    const vestingEndDateDjs = cliffEndDateDjs.add(
      Number(durationData),
      'seconds',
    )
    if (currentTime < tgeAtDjs) {
      vestingStatus = 'locked'
    } else if (currentTime < cliffEndDateDjs) {
      vestingStatus = 'cliff'
    } else if (currentTime < vestingEndDateDjs) {
      vestingStatus = 'unlocking'
    } else if (currentTime >= vestingEndDateDjs) {
      vestingStatus = 'unlocked'
    }
    const totalTokens = idoData.totalTokens
      ? (ethers.utils
          .parseUnits(`${idoData.totalTokens}`, Number(tokenDecimalData))
          .toBigInt() as bigint)
      : tgeAmount + vestingAmount
    if (vestingStatus === 'unlocked' && claimedAmountData === totalTokens) {
      vestingStatus = 'claimed'
    }
    // todo: add airdrop amount
    commonVestingStats = {
      tokenSymbol: idoData.claimRefund ? 'USDC' : idoData.project.token.symbol,
      totalTokens,
      claimableTokens: getClaimableAmountData,
      tokenDecimals: Number(tokenDecimalData),
      claimedTokens: claimedAmountData,
      vestingStatus,
      vestingEndAt: vestingEndDateDjs.unix(),
      remainingTokens: totalTokens - getClaimableAmountData - claimedAmountData,
    }
  }
  if (unifiedVestingStats) {
    const {
      allocationAmountBI,
      claimableAmountBI,
      claimedAmountBI,
      projectData,
      tokenSymbol,
      refundTokenSymbol,
      refundedAt,
    } = unifiedVestingStats
    // consoleLog('projectData', projectData)
    const { tgeAt, cliffDuration, vestingDuration } = projectData
    let vestingStatus: string = ''
    const tgeAtDjs = dayjs(Number(tgeAt) * 1000)
    const cliffEndAtDjs = tgeAtDjs.add(Number(cliffDuration), 'seconds')
    const vestingEndAtDjs = cliffEndAtDjs.add(
      Number(vestingDuration),
      'seconds',
    )
    if (currentTime < tgeAtDjs) {
      vestingStatus = 'locked'
    } else if (currentTime < cliffEndAtDjs) {
      vestingStatus = 'cliff'
    } else if (currentTime < vestingEndAtDjs) {
      vestingStatus = 'unlocking'
    } else if (currentTime >= vestingEndAtDjs) {
      vestingStatus = 'unlocked'
    }

    if(refundedAt > 0) {
      vestingStatus = 'refunded'
    }

    commonVestingStats = {
      tokenSymbol,
      refundTokenSymbol,
      totalTokens: isRefundOnly ? projectData.refundAmount : allocationAmountBI,
      claimableTokens: claimableAmountBI,
      claimedTokens: claimedAmountBI,
      tokenDecimals: projectData.tokenDecimals,
      refundDeadlineAt: Number(projectData.refundDeadlineAt),
      refundTokenAddr: projectData.refundTokenAddr,
      vestingStatus,
      remainingTokens: allocationAmountBI - claimableAmountBI - claimedAmountBI,
      vestingEndAt: vestingEndAtDjs.unix(),
    }
  }

  const {
    remainingTokens,
    totalTokens,
    claimableTokens,
    claimedTokens,
    tokenDecimals,
    vestingStatus,
    tokenSymbol,
    vestingEndAt,
  } = commonVestingStats

  // legacy claim
  const onClaim = async () => {
    try {
      setIsLoading(true)
      if (!claimContract) return

      // Await the transaction result
      const txHash = await claimToken({
        abi: ClaimNewAbi,
        address: CONTRACT_ADDRESS.APE_VESTING_UNIFIED[
          IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC
        ] as `0x${string}`,
        functionName: 'claimToken',
        args: [idoData?.project?.vestingId, proof],
      })

      consoleLog('tx hash', _.get(txHash, 'hash'))
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tx = await provider.getTransaction(txHash);
      // Wait for 2 confirmations
      await tx.wait(2)
      setNonce(current => current + 1)
    } catch (e) {
      console.error('onClaim error', e)
    } finally {
      console.log('finally')
      setIsLoading(false)
    }
  }

  // unified vesting claim
  const onUVClaim = async () => {
    if (!address) {
      consoleLog('No wallet connected')
      return
    }
    try {
      setIsLoading(true)
      const txHash = await claimToken({
        abi: ClaimNewAbi,
        address: CONTRACT_ADDRESS.APE_VESTING_UNIFIED[
          IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC
        ] as `0x${string}`,
        functionName: 'claimToken',
        args: [idoData?.project?.vestingId, proof],
      })
      consoleLog('tx hash', _.get(txHash, 'hash'))
      const provider = new ethers.providers.Web3Provider(window.ethereum);
      const tx = await provider.getTransaction(txHash);
      await tx.wait(2)
      consoleLog('tx confirmed', _.get(tx, 'hash'))
      setNonce(current => current + 1)
      setOpenClaimSuccessModal(true)
    } catch (e) {
      console.error('onUVClaim error', e)
      setIsLoading(false)
      toaster.error(getExceptionMessage(e))
    }
  }

  const onUVRefundRequest = async () => {
    // const claimContract = idoData?.claimContract
    const claimContract = idoData?.claimContract || CONTRACT_ADDRESS.APE_VESTING_UNIFIED[claimChainId!]
    const claimProjectId = idoData?.claimProjectId || Number(idoData?.project?.vestingId)
    // const claimProjectId = idoData?.claimProjectId
    consoleLog('idoData ', idoData, claimContract, claimProjectId, claimChainId, chainId);
    if(!address) {
      consoleLog("No wallet connected");
      return;
    }
    if (!claimContract || !(claimProjectId >= 0)) {
      consoleLog('No claimContract has been set')
      return
    }
    if (!claimChainId) throw new Error("claimChainId is not set");
    if(chainId != claimChainId) {
      toaster.error(`Please switch network to ${getChainName(claimChainId)}`);
      await switchChain(wagmiConfig, { chainId: claimChainId });
      return;
    }
    try {
      setIsLoading(true)
      const vestingContract = new ethers.Contract(
        claimContract,
        VESTING_UNIFIED_ABI as any[],
        signer,
      )
      consoleLog('vestingContract', vestingContract, investmentProjectId, address, apeInvestmentUnifiedContract)

      try {
        const investmentProof =
        await apeInvestmentUnifiedContract.getInvestmentProof(
          investmentProjectId,
          address,
        )

        consoleLog('investmentProof', investmentProof, proof)
      } catch (error) {
        console.info('error ==>>', error);
      }
      let estGas :ethers.BigNumber|BigInt = await vestingContract.estimateGas.requestRefund(
        claimProjectId,
        proof,
      )

      consoleLog('estGas', estGas)
      estGas = estGas.toBigInt();
      if(estGas < MIN_GAS_LIMIT.UV_REFUND_REQUEST) {
        estGas = MIN_GAS_LIMIT.UV_REFUND_REQUEST;
      }

      console.info('vestingContract ==>>', vestingContract, claimProjectId, proof, estGas);
      const tx = await vestingContract.requestRefund(
        claimProjectId,
        proof,
        {
          gasLimit: estGas,
        }
      )
      consoleLog('tx hash', tx, _.get(tx, 'hash'))
      await tx.wait(2)
      consoleLog('tx confirmed', _.get(tx, 'hash'))
      setNonce(current => current + 1)
    } catch (error) {
      console.error('onUVRefundRequest error', error)
      toaster.error(getExceptionMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const onUVRefundClaim = async () => {
    try {
      if (!claimContract || !(claimProjectId >= 0)) {
        console.error('No vesting contract has been set')
        return
      }
      if (!claimChainId) throw new Error("claimChainId is not set");
      setIsLoading(true)
      let tx: any
      let proof: any
      if (
        projectOid === PONDER_PROJECT_OID ||
        projectOid === MICROGPT_PROJECT_OID
      ) {
        const refundProofGenerator =
          '0xc57b048deac276aa6a1d267d06d24af226fc913c'
        const refundUserList: string[] = (
          refundUserByProject as Record<string, string[]>
        )[projectOid]
        const userIndex = refundUserList.findIndex(addr => addr == address)
        const proofGeneratorContract = new ethers.Contract(
          refundProofGenerator,
          ApeRefundProofGeneratorAbi,
          new ethers.providers.JsonRpcProvider(RPC_URL[CHAIN_ID.ETH]),
        )
        proof = await proofGeneratorContract.getRefundProof(
          refundUserList,
          userIndex,
        )
      } else if(projectOid == BRAVO_READY_OID) {
        // bravo ready is a cancelled project so every one get refund
        proof = await apeInvestmentUnifiedContract['getInvestmentProof(uint256,address)'](investmentProjectId, address);
      } else {
        const claimContractInstance = new Contract(claimContract, VestingUnifiedAbi, new ethers.providers.JsonRpcProvider(RPC_URL[claimChainId]));
        proof = await claimContractInstance['getRefundProof(uint256,address)'](claimProjectId, address);
      }
      // Refund always on BSC
      if(chainId != refundChainId) {
        toaster.error(`Please switch network to ${getChainName(refundChainId)}`);
        await switchChain(wagmiConfig, { chainId: IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC });
        return;
      }
      const refundContract = new ethers.Contract(
        CONTRACT_ADDRESS.APE_REFUND_UNIFIED[IS_DEV_MODE ? CHAIN_ID.BSC_TESTNET : CHAIN_ID.BSC],
        REFUND_UNIFIED_ABI as any[],
        signerBSC,
      )

      tx = await refundContract['claimRefundToken(uint256,bytes32[])'](
        refundProjectId,
        proof,
      )
      consoleLog('tx hash', tx, _.get(tx, 'hash'))
      if (tx) {
        await tx.wait(2)
        setNonce(current => current + 1)
        setOpenClaimSuccessModal(true)
      }
    } catch (error) {
      console.error('onUVRefundClaim error', error)
      toaster.error(getExceptionMessage(error))
    } finally {
      setIsLoading(false)
    }
  }

  const getVestingStatusText = (vestingStatus?: string) => {
    if (!vestingStatus) return ''
    const map: Record<string, any> = {
      locked: 'Locked',
      cliff: 'Cliff',
      unlocking: 'Unlocking',
      unlocked: 'Unlocked',
      claimed: 'Claimed',
      refunded: 'Refunded',
    }
    return map[vestingStatus] || ''
  }

  const getClaimableText = () => {
    if (!claimableTokens) return '0';
 
    let amount = formatEthBalanceLite(claimableTokens || BigInt(0),tokenDecimals);

    return Number(amount) < 0.01 ? '<0.01' : amount.toString();
  }

  const getClaimInfoText = (amount: any) => {
    if (!amount) return '0';

    amount = formatEthBalanceLite(amount || BigInt(0),tokenDecimals);

    return Number(amount) < 0.01 ? '<0.01' : amount.toString();
  }

  const epoch = getEpoch()
  const secondsPerDay = 3600 * 24;

  return (
    <Box>
      <Typography
        sx={{
          fontFamily: 'Hellix',
          fontSize: '24px',
          fontWeight: 600,
          color: '#000',
          margin: '0px',
          lineHeight: '31.2px',
          paddingBottom: '24px',
        }}
      >
        Claim Details
      </Typography>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics1}
          headingText="Available for Claim"
          headingVal={formatBigNumber(
            unifiedVestingClaimInfoStats?.claimableAmountBI || BigInt(0),
            unifiedVestingClaimInfoStats?.tokenDecimals,
          )}
          spanText={tokenSymbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics2}
          headingText="Total Tokens"
          headingVal={formatBigNumber(
            unifiedVestingClaimInfoStats?.tokenDeposited || BigInt(0),
            unifiedVestingClaimInfoStats?.tokenDecimals,
          )}
          spanText={tokenSymbol}
        />
      </StyledBox>
      <StyledBox>
        <InfoBox
          imageSrc={ClaimMetrics3}
          headingText="Claimed Tokens"
          headingVal={formatBigNumber(
            unifiedVestingClaimInfoStats?.claimedAmountBI || BigInt(0),
            unifiedVestingClaimInfoStats?.tokenDecimals,
          )}
          spanText={tokenSymbol}
        />
        <InfoBox
          imageSrc={ClaimMetrics4}
          headingText="Vesting Progress"
          headingVal={getVestingStatusText(vestingStatus)}
          hasGradient={false}
          multipleValues={
            (isRefundOnly || vestingStatus === 'unlocked' || vestingStatus == 'claimed')
              ? []
              : [
                  {
                    headingText: 'Vesting Finishes in: ',
                    headingVal: unifiedVestingClaimInfoStats?.vestingDuration ? (
                      <CountdownText
                        targetEpoch={unifiedVestingClaimInfoStats?.vestingDuration}
                        styles={{
                          color: '#000',
                        }}
                      />
                    ) : (
                      'n/a'
                    ),
                  },
                  {
                    headingText: 'Remaining Tokens: ',
                    headingVal: formatBigNumber(
                      unifiedVestingClaimInfoStats?.tokenRemains || BigInt(0),
                      unifiedVestingClaimInfoStats?.tokenDecimals,
                    ),
                    headingColor: '#fff',
                  },
                ]
          }
        />
      </StyledBox>

      <Box display={'flex'} gap={'1rem'} flexWrap={'wrap'}>
        {Boolean(
          !isUnifiedVesting &&
            ((claimableTokens && claimableTokens > BigInt(0n)) ||
              (remainingTokens && remainingTokens > BigInt(0))),
        ) && (
          <OrangeButton
            fs={15}
            lh="22.5px"
            fm="Inter"
            fw={400}
            br="24px"
            IconStart={iconStartFn}
            text={'Claim'}
            disabled={claimableTokens === BigInt(0) || isLoading}
            mt={3}
            onClick={() => {
              onClaim()
            }}
            w="137px"
            h="48px"
            sx={{
              textTransform: 'none',
              opacity: isLoading ? '0.8' : '1',
            }}
          />
        )}
        {_.isObject(unifiedVestingStats) && (
          <>
            {/* user can claim if they has not requested for a refund */}
            {/* won't show this button if user has fully claimed */}
            {unifiedVestingStats.refundRequestedAt === 0 &&
              unifiedVestingStats.claimedAmountBI <
                unifiedVestingStats.allocationAmountBI && (
                <OrangeButton
                  fs={15}
                  lh="22.5px"
                  fm="Inter"
                  fw={400}
                  br="24px"
                  IconStart={iconStartFn}
                  text={'Claim'}
                  disabled={
                    isLoading ||
                    unifiedVestingStats.claimableAmountBI === BigInt(0)
                  }
                  mt={3}
                  onClick={() => {
                    onUVClaim()
                  }}
                  w="137px"
                  h="48px"
                  sx={{
                    textTransform: 'none',
                    opacity:
                      isLoading ||
                      unifiedVestingStats.claimableAmountBI === BigInt(0)
                        ? '0.8'
                        : '1',
                  }}
                />
              )}
            {
              /* can only request refund if they has not claimed or requested for a refund */
              Boolean(
                unifiedVestingStats.refundDeadlineAt &&
                  unifiedVestingStats.refundRequestedAt === 0 &&
                  unifiedVestingStats.claimedAmountBI === BigInt(0) &&
                  currentTime.unix() < unifiedVestingStats.refundDeadlineAt,
              ) && (
                <GreenButton
                  fs={15}
                  lh="22.5px"
                  fm="Inter"
                  fw={400}
                  br="24px"
                  IconStart={iconStartFn}
                  disabled={isLoading}
                  mt={3}
                  onClick={() => {
                    onUVRefundRequest()
                  }}
                  outlined={true}
                  h="48px"
                  w="auto"
                  border={'1px solid #08493C'}
                  sx={{
                    background: '#000000 !important',
                    border: '1px solid #08493C !important',
                    opacity: isLoading ? '0.8' : '1',
                  }}
                  content={
                    <>
                      <span style={{ color: '#63EA71', textTransform: 'none' }}>
                        <span>
                          {'Request Refund'}
                        </span>
                        <span style={{ color: '#63EA71', textTransform: 'none' }}>
                          {unifiedVestingStats.refundDeadlineAt && (
                            <CountdownText
                              onComplete={() => {
                                setNonce(n => n + 1)
                              }}
                              hideOnCompleted={true}
                              prefixText=" ("
                              postfixText=" remaining)"
                              targetEpoch={unifiedVestingStats.refundDeadlineAt}
                              styles={{
                                color: '#63EA71',
                              }}
                            />
                          )}
                        </span>
                      </span>
                    </>
                  }
                />
              )
            }
            {
              /* user can claim a refund after refund deadline if they have requested for a refund before */
              (unifiedVestingStats.refundRequestedAt > 0 && !unifiedVestingStats.refundedAt) && (
                <>
                  <GreenButton
                    fs={15}
                    lh="22.5px"
                    fm="Inter"
                    fw={400}
                    br="24px"
                    IconStart={iconStartFn}
                    content={
                      <span style={{ color: '#63EA71', textTransform: 'none' }}>
                        <span>
                          {unifiedVestingStats.refundedAt
                            ? 'Refunded'
                            : 'Claim Refund'}
                        </span>
                        <span style={{ color: '#63EA71', textTransform: 'none' }}>
                          {unifiedVestingStats.refundDeadlineAt && epoch < (unifiedVestingStats.refundDeadlineAt + secondsPerDay) && !unifiedVestingStats.isRefundTokenReady && (
                            <CountdownText
                              onComplete={() => {
                                setNonce(n => n + 1)
                              }}
                              hideOnCompleted={true}
                              prefixText=" (in "
                              postfixText=')'
                              targetEpoch={unifiedVestingStats.refundDeadlineAt + secondsPerDay}
                              styles={{
                                color: '#63EA71',
                              }}
                            />
                          )}
                        </span>
                      </span>
                    }
                    disabled={(unifiedVestingStats.refundDeadlineAt || 0) > epoch || !!unifiedVestingStats.refundedAt || !unifiedVestingStats.isRefundTokenReady || isLoading}
                    mt={3}
                    onClick={() => {
                      onUVRefundClaim()
                    }}
                    outlined={true}
                    h="48px"
                    w="auto"
                    border={'1px solid #08493C'}
                    sx={{
                      background: '#000000 !important',
                      border: '1px solid #08493C !important',
                      opacity:
                        !!unifiedVestingStats.refundedAt || isLoading
                          ? '0.5'
                          : '1',
                    }}
                  />
                </>
              )
            }
          </>
        )}
      </Box>

      <ClaimSuccessModal
        open={openClaimSuccessModal}
        handleClose={() => setOpenClaimSuccessModal(false)}
        title="Claimed Successfully"
        subtext="Your tokens have been claimed successfully!"
        imageSrc={GreenTick}
      />

      <ClaimSuccessModal
        open={isShowClaimOnholdModal}
        handleClose={() => setIsShowClaimOnholdModal(false)}
        title="Available Soon"
        subtext="The Team are preparing the refund tokens. The tokens will be available in the next hour"
        imageSrc={GreenTick}
      />
    </Box>
  )
}

export default GeneralClaim
