import { api } from '../../api'

export enum TypeNoti {
  MANUAL = 'MANUAL',
  REGISTRATION = 'REGISTRATION',
  CONTRIBUTION = 'CONTRIBUTION',
}
export interface INotificationHistoryResponse {
  _id: string
  notification: string
  projectId: string
  startRegistration: string
  endRegistration: string
  investmentStartDate: string
  routeName: string
  type: TypeNoti
  time: string
  url?: string
  createdAt: string
  updatedAt: string
  __v: number
  title: string
  content: string
  isRead: boolean
  project: {
    routeName: string
    name: string
    logoUrl: string
    _id: string
  }
}

export interface IMarkNotificationAsRead {
  messagae: string
}

export interface INotificationHistoryAPIResponse {
  data: INotificationHistoryResponse[]
  meta: {
    page_size: number
    total_page: number
    total_records: number
  }
}

export const userApi = api.injectEndpoints({
  endpoints: build => ({
    // Fetch notification history
    fetchNotificationHistory: build.query<
      INotificationHistoryAPIResponse,
      { page: number; limit: number }
    >({
      query: ({ page, limit }) =>
        `/notification-history?page=${page}&limit=${limit}`,
    }),

    // Fetch notification history with authentication
    fetchNotificationHistoryAuth: build.query<
      INotificationHistoryAPIResponse,
      { page: number; limit: number }
    >({
      query: ({ page, limit }) =>
        `/notification-history/auth?page=${page}&limit=${limit}`,
    }),

    markNotificationAsRead: build.mutation<
      IMarkNotificationAsRead,
      { id: string }
    >({
      query: payload => ({
        url: `notification-history/${payload.id}/read-one`,
        method: 'PUT',
      }),
    }),
  }),
  overrideExisting: false,
})

export const {
  useFetchNotificationHistoryQuery,
  useLazyFetchNotificationHistoryQuery,
  useFetchNotificationHistoryAuthQuery,
  useLazyFetchNotificationHistoryAuthQuery,
  useMarkNotificationAsReadMutation,
} = userApi
