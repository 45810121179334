import { RPC_URL } from "../constant"
import { chainNames, preChainText } from "../components/ChainChangeModal"

export enum SupportedChainId {
  // MAINNET = 1,
  // ROPSTEN = 3,
  // RINKEBY = 4,
  GOERLI = 5,
  // KOVAN = 42,

  MANTLE_TESTNET = 5001,

  //   ARBITRUM_ONE = 42161,
  //   ARBITRUM_RINKEBY = 421611,

  //   OPTIMISM = 10,
  //   OPTIMISTIC_KOVAN = 69,

  //   POLYGON = 137,
  //   POLYGON_MUMBAI = 80001,

  //   CELO = 42220,
  //   CELO_ALFAJORES = 44787,
}

export const CHAIN_IDS_TO_NAMES = {
  // [SupportedChainId.MAINNET]: 'mainnet',
  // [SupportedChainId.ROPSTEN]: 'ropsten',
  // [SupportedChainId.RINKEBY]: 'rinkeby',
  [SupportedChainId.GOERLI]: 'goerli',
  // [SupportedChainId.KOVAN]: 'kovan',

  [SupportedChainId.MANTLE_TESTNET]: 'mantle_testnet',

  //   [SupportedChainId.POLYGON]: 'polygon',
  //   [SupportedChainId.POLYGON_MUMBAI]: 'polygon_mumbai',
  //   [SupportedChainId.CELO]: 'celo',
  //   [SupportedChainId.CELO_ALFAJORES]: 'celo_alfajores',
  //   [SupportedChainId.ARBITRUM_ONE]: 'arbitrum',
  //   [SupportedChainId.ARBITRUM_RINKEBY]: 'arbitrum_rinkeby',
  //   [SupportedChainId.OPTIMISM]: 'optimism',
  //   [SupportedChainId.OPTIMISTIC_KOVAN]: 'optimistic_kovan',
}

export interface ChainData {
  chainId: string
  chainName: string
  rpcUrls: string[]
  nativeCurrency: {
    name: string
    symbol: string
    decimals: number
  }
  blockExplorerUrls: string[]
}

export const chainConfig: { [key: string]: ChainData } = {
  '0x1389': {
    chainId: '0x1389',
    chainName: 'Mantle testnet',
    rpcUrls: ['https://rpc.testnet.mantle.xyz'],
    nativeCurrency: {
      name: 'BIT',
      symbol: 'BIT',
      decimals: 18,
    },
    blockExplorerUrls: ['https://explorer.testnet.mantle.xyz/'],
  },
  '0xa86a': {
    chainId: '0xA86A',
    chainName: 'Avalanche C-Chain',
    rpcUrls: ['https://api.avax.network/ext/bc/C/rpc'],
    nativeCurrency: {
      name: 'AVAX',
      symbol: 'AVAX',
      decimals: 18,
    },
    blockExplorerUrls: ['https://43114.routescan.io'],
  },
  '0x2105': {
    chainId: '0x2105',
    chainName: 'Base',
    rpcUrls: ['https://mainnet.base.org/'],
    nativeCurrency: {
      name: 'ETH',
      symbol: 'ETH',
      decimals: 18,
    },
    blockExplorerUrls: ['https://basescan.org'],
  },
  '0x61': {
    chainId: preChainText[97],
    chainName: chainNames[97],
    rpcUrls: [RPC_URL.BSC_TESTNET],
    nativeCurrency: {
      name: 'BNB',
      symbol: 'BNB',
      decimals: 18,
    },
    blockExplorerUrls: ['https://testnet.bscscan.com/'],
  },
}
